import React, { useCallback, useContext, useState } from 'react';
import EmpresaDropdown from '../../components/empresas-select/empresas-dropdown';
import { EmpresaOutput } from '../../services/EmpresaService';

import FaturamentoService, { InclusaoDoFaturamentoDaEmpresaInput } from '../../services/FaturamentoService';

import './Faturamento.css';
import Spinner from '../../components/spinner/spinner';
import Voltar from '../../components/voltar/voltar';
import AppContext from '../../contexts/AppContext';
import AmbienteService from '../../services/AmbienteService';
import Feedback from '../../components/feedback/feedback';
import useFeedback from '../../hooks/use-feedback';
import Button from '../../components/button/button';
import { Parametrizacao } from '../../models/Parametrizacao';
import { ParametrizacaoDescricao, ParametrizacaoParametro } from '../../helpers/constantes/const-parametrizacao';
import FormattedInput from '../../components/FormattedInput/FormattedInput';

const Faturamento = () => {
  const { estaAtualizando, setAtualizando } = useContext(AppContext);
  const [year, setYear] = useState('');
  const [parametroTituloCustomizado, setParametroTituloCustomizado] = useState('');
  const [columns, setColumns] = useState<Coluna[]>(defaultColumns);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<EmpresaOutput | null>(null);
  const [salvando, setSalvando] = useState(false);
  const { feedbacks, adicionarFeedbackDeAviso, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks } = useFeedback();

  const aoAlterarAnoHandler = useCallback((value: string) => {
    if (value.length <= 4) {
      setYear(value);
    }
  }, []);

  const adicionarColuna = useCallback(() => {
    if (year !== '' && year.length >= 4 && !columns.some(column => column.id === year)) {
      const newcolumn: Coluna = {
        id: year,
        monthlyBilling: Array(12).fill('')
      };
      setColumns(prevcolumns => [...prevcolumns, newcolumn]);
      setYear('');
    }
  }, [columns, year]);

  const removecolumn = useCallback((columnId: string) => {
    setColumns(prevcolumns => prevcolumns.filter(column => column.id !== columnId));
  }, []);

  const handleBillingChange = useCallback((columnId: string, monthIndex: number, value: string | undefined) => {
    setColumns(prevcolumns =>
      prevcolumns.map(column => {
        if (column.id === columnId) {
          const updatedMonthlyBilling = [...column.monthlyBilling];
          updatedMonthlyBilling[monthIndex] = value || "";
          return { ...column, monthlyBilling: updatedMonthlyBilling };
        }
        return column;
      })
    );
  }, []);

  const aoAlterarEmpresaHandler = useCallback((empresa: EmpresaOutput | null) => {
    (async () => {
      if (empresa) {
        setEmpresaSelecionada(empresa);

        const faturamento = await FaturamentoService.obterFaturamentoDaEmpresa(empresa.id);
        setColumns(_ => {
          let resultado = faturamento
            .faturamentosAnuais.map(ano => ({
              id: `${ano.ano}`,
              monthlyBilling: ano.faturamentosMensais.reduce((prev, current) => {
                prev[monthKeys[current.mes]] = `${current.valor}`.replaceAll('.', ',');
                return prev;
              }, Array(12).fill('') as string[])
            }));
          return resultado;
        })
      } else {
        setEmpresaSelecionada(null);
        setColumns(_ => defaultColumns);
      }
    })();
  }, []);

  const handleSubmit = useCallback(async (processarInclusaoDoFaturamento: (x: InclusaoDoFaturamentoDaEmpresaInput) => Promise<void>) => {
    limparFeedbacks();
    (async () => {
      if (empresaSelecionada && columns) {
        let parametro: Parametrizacao = {
          Parametro: ParametrizacaoParametro,
          Valor: parametroTituloCustomizado,
          Descricao: ParametrizacaoDescricao
        }

        const input = {
          empresaId: empresaSelecionada.id,
          parametrizacao: parametro,
          faturamentosAnuais: columns.map(ano => ({
            ano: parseInt(ano.id),
            faturamentosMensais: ano.monthlyBilling
              .map((valor, index) => ({
                mes: monthNames[index],
                valor: parseFloat(valor.replaceAll('.', '').replaceAll(',', '.')) || 0
              }))
              .filter(x => !!x.valor)
          }))
        };
        setSalvando(true);
        try {
          await processarInclusaoDoFaturamento(input);
        } catch (error) {
          adicionarFeedbackDeErro((error as Error).message);
        }
        setSalvando(false);
      }
    })();
  }, [empresaSelecionada, columns, limparFeedbacks, adicionarFeedbackDeErro, parametroTituloCustomizado]);

  const salvarFaturamento = useCallback(async (input: InclusaoDoFaturamentoDaEmpresaInput): Promise<void> => {
    await FaturamentoService.salvarFaturamentoDaEmpresa(input);
    adicionarFeedbackDeSucesso("Sucesso ao salvar faturamento da empresa!");
  }, [adicionarFeedbackDeSucesso]);

  const atualizarAmbienteDeDados = useCallback(async (): Promise<void> => {
    await AmbienteService.atualizarVersaoDoAmbiente('pessoal');
    setAtualizando(true);
  }, [setAtualizando]);

  const salvarFaturamentoEAtualizarAmbienteDeDados = useCallback(async (input: InclusaoDoFaturamentoDaEmpresaInput): Promise<void> => {
    await salvarFaturamento(input);
    adicionarFeedbackDeAviso("Atualizando ambiente de dados...");
    await atualizarAmbienteDeDados();
  }, [atualizarAmbienteDeDados, salvarFaturamento, adicionarFeedbackDeAviso]);

  return (
    <>
      <h1 className="top-title"><Voltar />Entrada Financeira</h1>
      <div className='formulario faturamento'>
        <div className='grid-empresa'>
          <div className="month-names">
            <label className="company-label" htmlFor="company">Rótulo do Faturamento no Painel de Gastos: </label>
            <input
              className="text-input"
              maxLength={50}
              type="text"
              placeholder="Descrição avulsa sobre o Faturamento"
              onChange={e => setParametroTituloCustomizado(e.target.value)} />
          </div>
          <div className="month-names">
            <EmpresaDropdown disabled={salvando} onChange={aoAlterarEmpresaHandler} />
          </div>
        </div>

        <div className="grid">
          <div className="month-names">
            <div className="input-fields">
              <input
                className="year-input"
                type="number"
                maxLength={4}
                placeholder="ano"
                value={year}
                disabled={salvando}
                onChange={e => aoAlterarAnoHandler(e.target.value)}
              />
              <button className="add-column-button" disabled={salvando} onClick={adicionarColuna}>
                +
              </button>
            </div>
            {monthRows}
          </div>
          <div className="billings-area">
            {columns.sort((a, b) => b.id.localeCompare(a.id)).map(column => (
              <div key={column.id} className="column-container">
                <div className="year-header">
                  <div className="year">
                    {column.id}
                  </div>
                  <button className="remove-column-button" onClick={() => removecolumn(column.id)}>&#215;</button>
                </div>
                {[...Array(12)].map((_, index) => (
                  <React.Fragment key={index}>
                    <FormattedInput
                      initialValue={columns[columns.findIndex(c => c.id === column.id)].monthlyBilling[index]}
                      onChange={(newValue : any) => handleBillingChange(column.id, index, newValue)}
                      disabled={salvando}
                      maxLength={50}
                      columnId={column.id}
                      Index={index}
                    />

                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
        {columns.length > 0 && (
          <div className='toolbar'>
            {salvando && <div><Spinner size={15} /></div>}
            <Feedback feedbacks={feedbacks} />
            <Button text={<>Salvar<br />Faturamento</>} classes="submit-button" disabled={salvando} onClick={() => handleSubmit(salvarFaturamento)} />
            <Button text={<>Salvar Faturamento<br />e Atualizar Painéis</>} classes="submit-button" disabled={salvando || estaAtualizando} onClick={() => handleSubmit(salvarFaturamentoEAtualizarAmbienteDeDados)} />
          </div>
        )}
      </div>
    </>
  );
};

export default Faturamento;

interface Coluna {
  id: string;
  monthlyBilling: string[];
}

const monthNames = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril',
  'Maio', 'Junho', 'Julho', 'Agosto',
  'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

const monthKeys = Object.keys(monthNames)
  .reduce((previousValue, currentValue) => {
    previousValue[monthNames[parseInt(currentValue)]] = currentValue;
    return previousValue;
  }, {} as any);

const monthRows = monthNames.map((monthName, index) => (
  <div key={index} className="month-name">
    {monthName}
  </div>
));

const defaultColumns = [
  {
    id: new Date().getFullYear().toString(),
    monthlyBilling: []
  }
];
