import { useCallback, useState } from 'react';

interface FormattedInputProps {
  initialValue: any,
  onChange: any,
  disabled: boolean,
  maxLength: number,
  columnId: string,
  Index: number
}

const formatValue = (val: number) => {
  const parts = val.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
};

const FormattedInput = ({ initialValue, onChange, disabled, maxLength, columnId, Index }: FormattedInputProps) => {
  const [value, setValue] = useState(initialValue || '');

  const handleChange = useCallback((e: any) => {
    const inputValue = e.target.value;
    let numericValue = inputValue.replace(/[^0-9,]/g, '');

    const decimalIndex = numericValue.indexOf('.');
    if (decimalIndex !== -1 && numericValue.length - decimalIndex > 3) {
      numericValue = numericValue.slice(0, decimalIndex + 3);
    }

    const formatar = formatValue(numericValue);
    setValue(formatar);
    onChange(formatar, e);
  }, [setValue, onChange]);

  return (
    <input
      type="text"
      value={formatValue(value)}
      onChange={handleChange}
      className={`company-billing-input ${disabled ? 'disabled' : ''}`}
      id={`input-example-${columnId}-${Index}`}
      name={`input-name-${columnId}-${Index}`}
      placeholder="0,00"
      disabled={disabled}
      maxLength={maxLength}
    />
  );
};

export default FormattedInput;
